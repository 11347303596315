<template>
    <div class="inner_pages">
        <!-- <inner-sidebar /> -->
        <div class="respective_content">
            <div class="top_bar">
                <a @click="backToPrevPage" class="back_btn"><i class="fas fa-long-arrow-alt-left"></i></a>
                <button type="button" class="contact_toggle" @click="toggleContactbar"><img src="@/assets/images/bar_menu.svg" alt=""></button>
                <swiper :slidesPerView="'auto'" :spaceBetween="0" :navigation='true' class="chat_listing">
                    <swiper-slide>
                        <div class="chat_tab" :class="{'active' : chatTab === 1}" @click="chatTab = 1">SMS <span class="superscript" v-if="stats.unreadCount > 0">{{ stats.unreadCount }}</span></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="chat_tab" :class="{'active' : chatTab === 2}" @click="chatTab = 2; portalChat = true;">Portal Chat <span class="superscript" v-if="portalChatStats.unreadCount > 0">{{ portalChatStats.unreadCount }}</span></div>
                    </swiper-slide>
                </swiper>
                <div class="tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                    <label>Tutorials</label><img src="@/assets/images/play.svg">
                    <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                        <ul>
                            <li @click="tutorial = true;">How to use the message center feature</li>
                        </ul>
                    </div>
                </div>
                <button type="button" class="filter_sidebar" @click="openFilter()"><img src="@/assets/images/menu.svg"></button>
                <div class="refresh_btn pointer" @click="handleRefresh"><i class="fa fa-sync-alt" :class="{'fa-spin': refreshLoader}"></i><label>Refresh</label></div>
            </div>
            <div class="mob_screen">
                <div class="header-search-main" v-if="chatTab === 1">
                    <div class="form-field">
                        <input type="text" name="search" @input="isTyping = true" v-model.trim="contactParams.search" placeholder="Search By Phone, Name or Tag">
                    </div>
                    <button class="header-search-btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <div class="header-search-main" v-if="chatTab === 2">
                    <div class="form-field">
                        <input type="text" name="search" v-model.trim="portalChatSearch" placeholder="Search By Phone, Name or Tag">
                    </div>
                    <button class="header-search-btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>
            <div class="smsCenter_wpr" v-if="chatTab === 1">
                <div class="contact_area">
                    <button type="button" class="toggle_bar" @click="toggleContactbar"><i class="fas fa-chevron-right"></i></button>
                    <div class="sms_action" :class="{'show' : actionFilter}">
                        <button type="button" class="toggle_bar" @click="toggleContactbar"><i class="fas fa-chevron-right"></i></button>
                        <button type="button" class="toggle_bar" @click="openFilter()"><i class="fas fa-chevron-right"></i></button>
                        <ul>
                            <li @click="changeSorting('newest')" :class="{ active : contactParams.sorting == 'newest' }">
                                <img src="@/assets/images/inbox2.svg" alt="">Inbox
                            </li>
                            <li @click="changeSorting('unread')" :class="{ active : contactParams.sorting == 'unread' }">
                                <img src="@/assets/images/unread.svg" alt="">Unread <span v-if="stats.unreadCount">{{ stats.unreadCount }}</span>
                            </li>
                            <li @click="changeSorting('flagged')" :class="{ active : contactParams.sorting == 'flagged' }">
                                <img src="@/assets/images/star2.svg" alt="">Starred <span v-if="stats.starredCount">{{ stats.starredCount }}</span>
                            </li>
                            <li @click="changeSorting('sent')" :class="{ active : contactParams.sorting == 'sent' }">
                                <img src="@/assets/images/sent.svg" alt="">Sent
                            </li>
                        </ul>
                        <ul class="other_actions">
                            <li @click="smsScheduled = true">
                                <img src="@/assets/images/calendar2.svg" alt="">Scheduled
                            </li>
                            <li  @click="hangechangeSorting('archived')" :class="{ active : contactParams.sorting == 'archived' }">
                                <img src="@/assets/images/archive2.svg" alt="">Archived
                            </li>
                        </ul>
                    </div>
                    <div class="user_list">
                        <ul class="message_list infinite-wrapper" v-if="contacts.length">
                            <li v-for="(contact, c) of contacts" :key="c" :class="{ active: contact.id == selectedContact.id }">
                                <div class="user_box" @click="handleChatLogs(contact)">
                                    <label for="robert" class="flag">
                                        <svg class="tag" :class="{ active: contact.starred }" @click.stop.prevent="toggleStarred($event, contact.id)" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.47 18.8"><path class="cls-1" d="M13.09,6.25l-.43-1.09c-.35-.88-.79-2-1.17-3a1.64,1.64,0,0,0-3,0c-.38,1-.82,2.1-1.17,3L6.85,6.25s-3.36.29-3.95.37C1.25,6.84,1.09,7.8,1.09,7.8a1.58,1.58,0,0,0,.46,1.6h0l2.47,2,.9.74-.29,1.13c-.24.92-.54,2.1-.8,3.1a1.64,1.64,0,0,0,.62,1.73h0a1.63,1.63,0,0,0,1.83.06h0L9,16.47l1-.63,1,.63,2.7,1.72a1.63,1.63,0,0,0,1.84-.05h0a1.64,1.64,0,0,0,.62-1.73c-.26-1-.56-2.18-.8-3.1L15,12.18l.91-.74,2.47-2h0a1.63,1.63,0,0,0-.94-2.88h0l-3.2-.2Z" transform="translate(-0.26 -0.4)"/></svg>
                                    </label>
                                    <div class="img_wpr pointer">
                                        <img :src="contact.photo">
                                        <span class="active"></span>
                                    </div>
                                    <div class="user_info">
                                        <h4 class="pointer">{{ contact.name }} <span>{{ contact.last_sms ? moment.utc(contact.last_sms.date_sent).local().fromNow() : '' }}</span></h4>
                                        <div class="msg_cont">
                                            <p class="pointer" v-if="contact.last_sms">{{ contact.last_sms.body}}</p>
                                            <span class="read pointer" @click.stop="actionMenu($event)" v-click-outside="closeActionMenu">
                                                <i class="fas fa-ellipsis-v"></i>
                                                <div class="dropdown_wpr">
                                                    <ul>
                                                        <li @click="handleProfile(contact)">Open Profile</li>
                                                        <li v-if="contactParams.sorting !== 'archived'" @click="toggleMarkReadUnread($event, contact, 0)">Mark as read</li>
                                                        <li v-if="contactParams.sorting !== 'archived'"  @click="toggleMarkReadUnread($event, contact, 1)">Mark as unread</li>
                                                        <li @click="toggleAddNote(contact)">Add a note</li>
                                                        <li @click="toggleTag(contact)">Add a tag</li>
                                                        <li @click="toggleUpdateSegment(contact)">Update segment</li>
                                                        <li @click="handleArchiveUnarchiveChat(0, contact)" v-if="contactParams.sorting == 'archived'">Unarchive</li>
                                                        <li @click="handleArchiveUnarchiveChat(1, contact)" v-else>Archive chat</li>
                                                    </ul>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <infinite-loading class="infinite-loading" @infinite="handleContacts" target=".infinite-wrapper" :distance="4" :identifier="resetInfiniteWatcher" />
                        </ul>
                        <div class="empty_text" v-if="contacts.length == 0 && !loader">
                            <img src="@/assets/images/empty_chat.svg">
                            <h4>No contact found.</h4>
                        </div>
                        <infinite-loading v-if="loader && !contacts.length"/>
                    </div>
                </div>
                <div class="msg_board" :class="{'show' : showConversation}">
                    <div class="header-search-main">
                        <div class="form-field">
                            <input type="text" name="search" @input="isTyping = true" v-model.trim="contactParams.search" placeholder="Search By Phone, Name or Tag">
                        </div>
                        <button class="header-search-btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <div class="chat_board">
                        <div class="user_header" v-if="selectedContact.id">
                            <template v-if="selectedContact.id">
                                <a @click="showConversation = false;" class="back"><i class="fas fa-long-arrow-alt-left"></i></a>
                                <h3 @click="profile = true">
                                    <img :src="selectedContact.photo" alt="" class="mr-3 user_img">{{ selectedContact.name }}
                                </h3>
                                <ul class="chatbox-header">
                                    <li class="read pointer" @click.stop="actionMenu($event)" v-click-outside="closeActionMenu">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <div class="dropdown_wpr">
                                            <ul>
                                                <li @click="handleProfile(selectedContact)">Open Profile</li>
                                                <li v-if="contactParams.sorting !== 'archived'"  @click="toggleMarkReadUnread($event, selectedContact, 0)">Mark as read</li>
                                                <li v-if="contactParams.sorting !== 'archived'"   @click="toggleMarkReadUnread($event, selectedContact, 1)">Mark as unread</li>
                                                <li @click="toggleAddNote(selectedContact)">Add a note</li>
                                                <li @click="toggleTag(selectedContact)">Add a tag</li>
                                                <li @click="toggleUpdateSegment(selectedContact)">Update segment</li>
                                                <li @click="handleArchiveUnarchiveChat(0, selectedContact)" v-if="contactParams.sorting === 'archived'">Unarchive</li>
                                                <li @click="handleArchiveUnarchiveChat(1, selectedContact)" v-else>Archive chat</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li @click="lauchPad = true" v-tooltip="`Open your launchpad and perform actions`" position="left">
                                        <img src="@/assets/images/launchpad.svg" alt="">
                                    </li>
                                </ul>
                            </template>
                        </div>
                        <chat-box v-if="selectedContact.id" :contact="selectedContact" ref="chatbox" :filter="contactParams.sorting" :mark-read="handleMarkRead" />
                        <div class="empty_chat" v-else>
                            <img src="@/assets/images/empty_chat.svg">
                            <h2>No conversations found.</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="chatTab === 2">
                <portal-chat v-model="portalChat" ref="portal-chat" :close-func="toggleContactbar" :is-filter="actionFilter" :filter-func="openFilter" :portal-chat-search="portalChatSearch" />
            </div>
            <div class="no-portal-chat" v-if="chatTab === 3">
                <h2>Coming Soon!</h2>
            </div>
        </div>
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/e7bb547c88874d7cbc9a97d7f00270c2?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
        <sms-schedule :is-all-schedule="false" v-model="smsScheduled" />
        <scheduled-sms v-model="scheduledSms" :contact-id="selectedContact.id" />
        <launch-pad v-model="lauchPad" :contact="selectedContact" />
        <profile-component v-model="profile" :contact="selectedContact" />
        <add-note-component v-model="addNote" :contacts="[selectedContact.id]" source="sms-center" />
        <add-tag-component v-model="addTag" :contacts="[selectedContact.id]" />
        <update-segment v-model="updateSegment" :contacts="[selectedContact.id]" :status="selectedContact.segment" />
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import SwiperCore, { Navigation } from 'swiper'
    import { defineAsyncComponent } from 'vue'
    SwiperCore.use([Navigation])

    const ChatBox = defineAsyncComponent(() => import('@/pages/sms-center/Chat'))
    const SmsSchedule = defineAsyncComponent(() => import('@/pages/admin-setting/components/report-schedule/Schedule.vue'))
    const ScheduledSms = defineAsyncComponent(() => import('@/pages/sms-center/ScheduledSms'))
    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))
    const PortalChat = defineAsyncComponent(() => import('@/pages/sms-center/portal-chat/PortalChat'))
    const AddNoteComponent = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/AddNote'))
    const AddTagComponent = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))
    const UpdateSegment = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/SwitchTab'))

    import InfiniteLoading from 'v3-infinite-loading'
    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import 'swiper/swiper-bundle.min.css'

    export default {
        name: 'SMS Center Dashboard',

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                barToggle: false,
                chatTab: 1,
                smsScheduled: false,
                moment,
                contactParams: {
                    per_page: 10,
                    page: 1,
                    last_page: 1,
                    search: '',
                    sorting: 'newest',
                },
                state: undefined,
                loader: false,
                contacts: [],
                isTyping: false,
                resetInfiniteWatcher: 0,
                selectedContact: {},
                scheduledSms: false,
                lauchPad: false,
                profile: false,
                portalChat: false,
                refreshLoader: false,
                showConversation: false,
                addNote: false,
                addTag: false,
                updateSegment: false,
                actionFilter: false,
                portalChatSearch: '',
            };
        },

        components: {
            ChatBox,
            InfiniteLoading,
            SmsSchedule,
            ScheduledSms,
            LaunchPad,
            ProfileComponent,
            Swiper,
            SwiperSlide,
            PortalChat,
            AddNoteComponent,
            AddTagComponent,
            UpdateSegment,
        },

        watch: {
            'contactParams.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.contactParams.search != null) {
                        if (vm.contactParams.search.length == 0 || vm.contactParams.search.length >= 2) {
                            vm.contactParams.page      = 1;
                            vm.contactParams.last_page = 1;
                            vm.contacts                = [];
                            vm.loader                  = false;
                            vm.resetInfiniteWatcher++;

                            vm.handleContacts(undefined, true);
                        }
                    }
                }
            },

            'contactParams.sorting' () {
                const vm  = this;

                vm.contactParams.page      = 1;
                vm.contactParams.search    = '';
                vm.contactParams.last_page = 1;
                vm.contacts                = [];
                vm.loader                  = false;
                vm.resetInfiniteWatcher++;

                vm.handleContacts(undefined, true);
            },

            receiveChatLogWatcher (val) {
                const vm = this;

                vm.getPortalChatStats();
            },

            chatTab (tab) {
                const vm = this;
                const contact = vm.$route.query && vm.$route.query.contact ? vm.$route.query.contact : '';

                if (tab == 2) {
                    vm.$router.replace({ name: 'SmsCenterIndex', query: { tab: 'portal-chat', contact }});
                } else {
                    vm.$router.replace({ name: 'SmsCenterIndex', query: { tab: 'sms-chat', contact }});
                }
            },
        },

        computed: mapState({
            stats: state => state.smsCenterModule.stats,
            portalChatStats: state => state.portalChatModule.stats,
            user: state => state.authModule.user,
            receiveChatLogWatcher: state => state.portalChatModule.receiveChatLogWatcher,
        }),

        mounted () {
            const vm = this;

            if (vm.$route.query.search) {
                vm.contactParams.search = vm.$route.query.search;
            }

            vm.handleContacts(undefined, true);
            vm.getStats();
            vm.getPortalChatStats();
            vm.getStatuses();

            if (vm.$route.query.tab && vm.$route.query.tab == 'portal-chat') {
                vm.chatTab    = 2;
                vm.portalChat = true
            }
        },

        methods: {
            ...mapActions({
                getContacts: 'smsCenterModule/getContacts',
                markContactAsStarred: 'contactModule/markContactAsStarred',
                getStats: 'smsCenterModule/getStats',
                updateMarkAsReadUnread: 'smsCenterModule/updateMarkAsReadUnread',
                archiveUnarchiveChat: 'smsCenterModule/archiveUnarchiveChat',
                getPortalChatStats: 'portalChatModule/getStats',
                getStatuses: 'contactModule/getStatuses',
            }),

            toggleStarred (e, contact_id) {
                e.stopPropagation();

                const vm    = this;
                let element = e.currentTarget;
                let starred = 1;

                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                    starred = 0;
                } else {
                    element.classList.add('active');
                    starred = 1;
                }

                vm.markContactAsStarred({ starred, contact_id }).then((result) => {
                    vm.getStats();
                    if (!result) {
                        if (starred == 1) {
                            element.classList.remove('active');
                        } else {
                            element.classList.add('active');
                        }
                    }
                });
            },

            handleContacts ($state, force = false) {
                const vm = this;

                if ($state) {
                    vm.state = $state;
                }

                if ((vm.contactParams.page <= vm.contactParams.last_page ) || force) {
                    if (!vm.loader) {
                        vm.loader = true;

                        vm.getContacts(vm.contactParams).then((result) => {
                            if (result && result.data && result.data.length) {
                                vm.contactParams.last_page = result.last_page;
                                vm.contactParams.page = result.current_page + 1;
                                vm.loader = false;
                                vm.refreshLoader = false;

                                const sorting = new URL(result.first_page_url).searchParams.get('sort');

                                if (vm.contactParams.sorting == sorting) {
                                    vm.contacts.push(...result.data);
                                }

                                setTimeout(function () {
                                    if (vm.state != undefined) {
                                        vm.state.loaded();
                                    }
                                }, 1000);

                                if ($state == undefined || (vm.contacts.length == result.data.length)) {
                                    vm.selectedContact = result.data[0];
                                }
                            } else {
                                if (vm.state != undefined) {
                                    vm.state.complete();
                                }

                                vm.loader           = false;
                                vm.refreshLoader    = false;
                            }
                        });
                    }
                } else {
                    if (vm.state != undefined) {
                        vm.state.complete();
                    }
                }
            },

            toggleMarkReadUnread (e, contact, value) {
                const vm = this;

                const params = {
                    value,
                    contact_id: contact.id,
                }

                vm.updateMarkAsReadUnread(params).then((result) => {
                    if (result.data != 0) {
                    } else {
                        Toastr.error('Mark as read/unread work only on inbound sms!');
                    }

                    vm.getStats();
                });
            },

            handleChatLogs (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.showConversation = true;

                vm.handleMarkRead();
            },

            handleArchiveUnarchiveChat (status, contact) {
                const vm = this;
                let message = 'This conversation will be moved to your archived folder. Ok to proceed?';

                if (status == 0) {
                    message = 'This conversation will be moved back to your inbox. Ok to proceed?';
                }

                const options = Helper.swalConfirmOptions('Are you sure?', message);
                options.preConfirm = () => {
                                                return vm.archiveUnarchiveChat({ status, contact_id: contact.id }).then((result) => {
                                                    if (result) {
                                                        if (status) {
                                                            const chatbox = vm.$refs.chatbox;

                                                            if (chatbox) {
                                                                chatbox.chatLogs = [];
                                                            } else {
                                                                chatbox.resetForm();
                                                            }
                                                        }
                                                    }
                                                })
                                            }

                Swal.fire(options);
            },

            toggleContactbar () {
                const leftSidebar = document.querySelector('.contact_area');

                if (leftSidebar.classList.contains('show')) {
                    leftSidebar.classList.remove('show');
                } else {
                    leftSidebar.classList.add('show');
                }
            },

            handleMarkRead () {
                const vm = this;

                    const el     = document.getElementById(`read-unread-${vm.selectedContact.id}`);
                    const params = { value: 0, contact_id: vm.selectedContact.id };

                    vm.updateMarkAsReadUnread(params).then((result) => {
                        if (result.data != 0) {

                        } else {
                            Toastr.error('Mark as read/unread work only on inbound sms!');
                        }

                        vm.getStats();
                    });
                // }
            },

            handleRefresh () {
                const vm = this;

                vm.refreshLoader = true;
                if (vm.chatTab == 1) {
                    const chatbox = vm.$refs.chatbox;

                    vm.handleContacts(undefined, true);

                    if (chatbox) {
                        chatbox.resetForm();
                    }
                } else {
                    const portalChat = vm.$refs['portal-chat'];

                    if (portalChat) {
                        setTimeout(function () {
                            const chatbox = vm.$refs['portal-chat'];

                            if (chatbox) {
                                chatbox.resetChatBox();
                                vm.refreshLoader = false;
                            }
                        }, 100);
                    }
                }
            },

            closeTutorialListDropdown () {
                const vm = this;

                vm.tutorialList = false;
            },

            actionMenu (ev) {
                const vm    = this;
                let el      = ev.currentTarget.closest('.read');
                let tarEl   = el.querySelector('.dropdown_wpr');

                if (tarEl.classList.contains('active')) {
                    tarEl.classList.remove('active');
                } else {
                    vm.closeActionMenu();

                    tarEl.classList.add('active');
                }
            },

            closeActionMenu () {
                let allTarEl    = document.querySelectorAll('.message_list .user_box .dropdown_wpr');
                let allTarEl2   = document.querySelectorAll('.msg_board .read .dropdown_wpr');

                allTarEl.forEach(function(item) {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                })

                allTarEl2.forEach(function(item) {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                })
            },

            handleProfile (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.profile = true;
            },

            backToPrevPage () {
                const vm        = this;
                const prevRoute = vm.$router.options.history.state.back;

                if (prevRoute) {
                    vm.$router.push(prevRoute);
                } else {
                    vm.$router.push({ name: 'ContactIndex' });
                }
            },

            toggleAddNote (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.addNote = true;
            },

            toggleTag (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.addTag = true;
            },

            toggleUpdateSegment (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.updateSegment = true;
            },

            openFilter () {
                const vm = this;
                vm.actionFilter = !vm.actionFilter;
            },

            changeSorting(sort){
                const vm = this

                if (vm.contactParams.sorting != sort) {
                    vm.contacts = []
                    vm.contactParams.sorting = sort
                    vm.actionFilter = false
                    vm.selectedContact = {}
                }
            },
        },
    }
</script>

<style scoped>

    .respective_content {
        min-height: auto;
    }
    .inner_pages{
        max-width: 1500px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .back_btn {
        width: 25px;
        height: 25px;
        text-decoration: none;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: absolute;
        left: 25px;
        top: 15px;
        z-index: 6;
        cursor: pointer;
    }
    .section_header{
        justify-content: flex-start;
        column-gap: 0;
    }
    .section_header h2{
        font-size: 20px;
        line-height: 27px;
    }
    .refresh_btn{
        padding: 6px 12px;
        margin-left: 10px;
        background: #fff;
        border-radius: 15px;
        color: #121525;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
    }
    .tutorial_btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        margin-left: 10px;
    }
    .tutorial_btn .dropdown_wpr{
        width: max-content;
        min-width: 160px;
        left: auto;
        right: 1px;
        z-index: 12;
        overflow: hidden;
    }
    .tutorial_btn .dropdown_wpr ul li{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        border: 0;
    }
    .tutorial_btn label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        cursor: pointer;
        min-width: auto;
    }
    .tutorial_btn img {
        max-height: 18px;
        width: auto;
        margin-left: 5px;
    }
    .smsCenter_wpr {
        display: flex;
        height: calc(100vh - 100px);
    }

    .msg_board {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
    .chat_board{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        /* background: #fff; */
        border-radius: 10px;
    }
    .msg_board .header-search-main {
        border: 0;
        height: 44px;
        flex-shrink: 0;
        border-radius: 25px;
        padding: 0 44px 0 0;
        margin-bottom: 10px;
        position: relative;
        background: #fff;
        overflow: hidden;
    }
    .msg_board .header-search-main .form-field{
        margin: 0;
        opacity: 1;
        width: 100%;
    }
    .msg_board .header-search-main .form-field input{
        font-size: 13px;
        line-height: 45px;
        padding: 0 0 0 20px;
        color: #999 !important;
    }
    .msg_board .header-search-main .form-field input:-webkit-autofill{
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
    .msg_board .header-search-main .header-search-btn {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 0;
        font-size: 13px;
        color: #2f7eed;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .msg_board .user_header {
        padding: 15px 10px 15px 20px;
        background: #fff;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        align-items: center;
    }
    .msg_board .user_header .back{
        width: 25px;
        height: 25px;
        text-decoration: none;
        border-radius: 50%;
        background: #eaeaea;
        display: none;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
        margin-right: 15px;
    }

    .msg_board .user_header h3 {
        font-size: 16px;
        line-height: 23px;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .msg_board .user_header > ul {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .msg_board .user_header > ul > li {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-left: 15px; */
        cursor: pointer;
    }

    .msg_board .user_header ul li img {
        width: 15px;
        height: auto;
    }

    .msg_board :deep(.chat) {
        border: 0;
        flex: 1 1 auto;
        height: calc(100vh - 350px);
    }
    .smsCenter_wpr .contact_area{
        display: flex;
        flex-shrink: 0;
        width: 500px;
        margin-right: 20px;
    }
    .mob_screen{
        padding: 0 15px;
    }
    .mob_screen .header-search-main{
        border: 0;
        background: #f5f5f5;
        height: 35px;
        margin-top: 9px;
        display: none;
    }
    .mob_screen .header-search-main .form-field input{
        height: 35px;
    }
    .mob_screen .header-search-main .header-search-btn{
        width: 40px;
        height: 35px;
        top: 0;
        right: 0;
    }
    .smsCenter_wpr .user_list {
        background: #fff;
        border-radius: 10px;
        padding: 15px 12px;
        height: 100%;
        flex: 0 0 325px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        position: relative;
    }
    .smsCenter_wpr .user_list::-webkit-scrollbar {
        width: 4px;
    }
    .smsCenter_wpr .user_list:hover::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 2px;
    }

    .smsCenter_wpr .user_list .search {
        padding: 20px 5px;
        position: sticky;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 1;
        display: flex;
        flex-direction: column;
    }
    .smsCenter_wpr .user_list .search .header-search-main{
        border: 0;
        background: #f5f5f5;
    }
    .smsCenter_wpr .user_list .search .header-search-main .form-field input:-webkit-autofill{
        -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
    }
    .smsCenter_wpr .user_list .search .header-search-main .form-field{
        margin: 0;
        opacity: 1;
    }

    .smsCenter_wpr .message_list .user_box {
        position: relative;
    }

    .smsCenter_wpr .message_list .user_box .flag {
        width: 15px;
        height: 15px;
        position: absolute;
        left: -12px;
        top: -14px;
        cursor: pointer;
    }

    .smsCenter_wpr .message_list .user_box .flag path {
        fill: none;
        stroke: #999;
        stroke-width: 1px;
    }

    .smsCenter_wpr .message_list .user_box .flag input[type=checkbox]:checked~svg path {
        fill: #F2A31D;
        stroke-width: 0;
    }
    .smsCenter_wpr .message_list .user_box .img_wpr{
        flex-shrink: 0;
    }
    .smsCenter_wpr .message_list .user_box .img_wpr span {
        width: 13px;
        height: 13px;
        border: 2px solid #fff;
        background: #ddd;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .smsCenter_wpr .message_list .user_box .img_wpr span.active {
        background: #30C13E;
    }

    .smsCenter_wpr .message_list .user_info {
        margin-left: 10px;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .smsCenter_wpr .msg_board .user_header .read{
        margin-left: 0;
        font-size: 15px;
        /* display: none; */
    }
    .smsCenter_wpr .msg_board .user_header .read i{
        color: #2f7eed;
    }
    .smsCenter_wpr .message_list .user_info .msg_cont .read .dropdown_wpr,
    .smsCenter_wpr .msg_board .user_header .read .dropdown_wpr{
        width: 130px;
        left: auto;
        right: 0;
        text-align: left;
    }
    .smsCenter_wpr .message_list .user_info .msg_cont .read .dropdown_wpr li,
    .smsCenter_wpr .msg_board .user_header .read .dropdown_wpr li{
        text-align: left;
        font-size: 12px;
        line-height: 16px;
    }
    .smsCenter_wpr .message_list .user_info .msg_cont .read .dropdown_wpr::before,
    .smsCenter_wpr .msg_board .user_header .read .dropdown_wpr::before{
        display: none;
    }

    .smsCenter_wpr .message_list li:hover .user_info .msg_cont .read {
        opacity: 1;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read img,
    .smsCenter_wpr .msg_board .user_header .read img {
        width: 17px;
        height: auto;
        border-radius: 0;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read img.unread {
        display: none;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read.unread img.readed {
        display: none;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read.unread img.unread {
        display: block;
    }

    .sms_action {
        padding: 10px;
        margin-right: 15px;
        flex: 0 0 160px;
        background: #fff;
        border-radius: 10px;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in-out;
    }
    .sms_action.active{
        flex: 0 0 120px;
    }

    .sms_action ul li {
        margin: 10px 0;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        border-radius: 10px;
        cursor: pointer;
        background: transparent;
        transition: all 0.3s ease-in-out;
        position: relative;
    }

    .sms_action ul li img {
        max-width: 18px;
        height: auto;
        margin-right: 8px;
        transition: all 0.3s ease-in-out;
    }

    .sms_action ul li span {
        background: #2f7eed;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
    }
    .sms_action.active ul li span{
        position: relative;
        top: auto;
    }

    .sms_action ul li.active,
    .sms_action ul li:hover {
        background: #e1eeff;
    }
    .sms_action ul.other_actions{
        margin-top: auto;
    }
    .sms_action ul.other_actions li{
        margin: 5px 0;
    }
    .modal.schedule .modal_container {
        height: 640px;
    }

    .schedule .modal_header {
        padding: 20px 30px 0;
    }

    :deep(.modal.profile_modal.schedule .modal_header) {
        padding: 20px 30px 0;
    }

    .schedule .modal_body {
        padding: 15px 30px;
        flex-direction: column;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .actions>ul li.delete {
        flex: 0 0 50px;
        cursor: pointer;
    }

    .actions>ul li.delete i {
        color: #f04438;
        margin: 0;
    }

    .actions>ul li .dropdown_wpr {
        max-height: 300px;
        overflow-y: scroll;
    }

    .actions>ul li .dropdown_wpr::-webkit-scrollbar {
        width: 4px;
    }

    .actions>ul li .dropdown_wpr:hover::-webkit-scrollbar-thumb {
        background-color: #c7c7c7;
        border-radius: 2px;
    }

    table.standard th {
        font-size: 13px;
        line-height: 16px;
        padding: 15px 12px;
    }

    table.standard td {
        font-size: 13px;
        line-height: 16px;
        padding: 18px 12px;
    }

    table.standard td .user_wpr h4 {
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
        letter-spacing: 0;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .smsCenter_wpr .message_list .user_box .flag .tag.active path {
        fill: #2f7eed;
        stroke: #2f7eed;
    }

    .msg_board .user_header .mr-3.user_img {
        max-width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .infinite-wrapper {
        height: 100vh;
        overflow-y: overlay;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .infinite-wrapper.message_list > li{
        padding: 20px 10px 15px 20px;
    }
    .message_list{
        padding-bottom: 30px;
    }
    .message_list > li{
        margin-bottom: 10px;
        border: 1px solid #f3f3f3;
        border-radius: 8px;
        transition: all 0.3s ease-in-out;
    }
    .message_list > li.active, .message_list > li:hover {
        background: #e1eeff;
        box-shadow: none;
        border: 1px solid transparent;
    }
    .message_list .user_box .img_wpr img{
        width: 40px;
        height: 40px;
    }
    .message_list .user_info h4{
        font-size: 13px;
        line-height: 18px;
        position: relative;
    }
    .message_list .user_info h4 span{
        position: absolute;
        top: -15px;
        right: 0;
        font-size: 11px;
    }
    .message_list .user_info p{
        font-size: 11px;
        line-height: 15px;
        word-break: break-all;
        color: #5a5a5a;
    }
    .message_list .user_info p:empty{
        min-height: 15px;
    }
    .infinite-loading{
        font-size: 15px;
        line-height: 20px;
        color: #777;
    }
    .empty_text {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .empty_text img{
        display: block;
        max-width: 70px;
        height: auto;
        margin: 0 auto 15px auto;
    }
    .empty_text h4{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #777;
    }
    .chatbox-header li {
        position: relative;
    }
    .toggle_bar{
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #e9e9e9;
        font-size: 10px;
        color: #5a5a5a;
        position: absolute;
        right: 0;
        top: 25px;
        cursor: pointer;
        display: none;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;
        z-index: 12;
    }
    .contact_area.show .toggle_bar {
        transform: rotate(-180deg);
        right: -11px;
    }
    .contact_area .sms_action .toggle_bar{
        position: fixed;
        top: 30px;
        left: -22px;
        transition: all .3s ease-in-out;
    }
    .contact_area .sms_action.show .toggle_bar{
        transform: rotate(-180deg);
        left: 150px;
    }
    .chat_listing{
        padding: 12px 0;
        max-width: 325px;
        width: 100%;
        margin-left: 175px;
    }
    .chat_listing :deep(.swiper-wrapper){
        justify-content: center;
    }
    .chat_listing .swiper-slide{
        width: auto;
    }
    .chat_listing .swiper-slide:not(:last-of-type):after{
        position: absolute;
        content: '';
        right: 0;
        top: 4px;
        bottom: 4px;
        border-right: 1px solid #c2c2c2;
    }
    .chat_listing .chat_tab{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #7a7a7a;
        padding: 0 20px;
        height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
    }
    .chat_listing .chat_tab img{
        max-height: 18px;
        height: auto;
        margin-right: 7px;
    }
    .chat_listing .chat_tab span{
        margin-left: 5px;
        background: #3f7eed;
        width: 16px;
        height: 16px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        border-radius: 9px;
        align-items: center;
        justify-content: center;
        display: none;
    }

    .chat_listing .chat_tab span.superscript {
        display: flex;
    }

    .chat_listing .chat_tab.active{
        color: #121525;
    }
    .chat_listing :deep(.swiper-button-next.swiper-button-disabled), .chat_listing :deep(.swiper-button-prev.swiper-button-disabled){
        opacity: 0;
    }
    .chat_listing :deep(.swiper-button-next:after), .chat_listing :deep(.swiper-button-prev:after){
        font-size: 13px;
        color: #5a5a5a;
        font-weight: 600;
    }
    .chat_listing :deep(.swiper-button-prev), .chat_listing :deep(.swiper-container-rtl .swiper-button-next){
        left: 0;
        width: 20px;
        background: #fff;
        cursor: pointer;
        top: 0;
        height: 100%;
        margin: 0;
    }
    .chat_listing :deep(.swiper-button-next), .chat_listing :deep(.swiper-container-rtl .swiper-button-prev){
        right: 0;
        width: 20px;
        background: #fff;
        cursor: pointer;
        top: 0;
        height: 100%;
        margin: 0;
    }
    .no-portal-chat{
        height: calc(100vh - 315px);
        background: #fff;
        border: 1px solid #eee;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .no-portal-chat h2{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
    }
    .top_bar{
        display: flex;
        align-items: center;
    }
    .top_bar .user_box{
        width: 35px;
        height: 35px;
        flex-shrink: 0;
        position: relative;
        margin-right: 15px;
        display: none;
    }
    .top_bar .user_box img{
        width: 100%;
        height: 100%;
    }
    .top_bar .user_box span{
        position: absolute;
        top: 0;
        right: -5px;
        border: 2px solid #fff;
        background: #2f7eed;
        height: 18px;
        padding: 0 5px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .top_bar button.contact_toggle, .top_bar button.filter_sidebar{
        width: 25px;
        flex-shrink: 0;
        border-radius: 5px;
        padding: 4px 4px;
        margin-right: 8px;
        line-height: 0;
        background: #fff;
        cursor: pointer;
        display: none;
    }
    .top_bar button.filter_sidebar{
        margin-right: 0;
    }
    .smsCenter_wpr .user_list .close_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        margin-bottom: 10px;
        cursor: pointer;
        display: none;
        margin-left: auto;
    }
    :deep(.emoji-picker .default-emoji-dropdown){
        bottom: 0;
        left: 25px;
        right: auto;
    }

    .empty_chat {
        background: #fff;
        border-radius: 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .empty_chat h2{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
    }
    .empty_chat img{
        display: block;
        max-width: 120px;
        height: auto;
        margin: 0 auto 20px auto;
    }
    :deep(.smsCenter_wpr .chat_client img){
        display: block;
        margin: auto 0 15px 10px;
    }
    :deep(.smsCenter_wpr .chat_user img) {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
        margin: auto 10px 15px 0;
    }
    :deep(.smsCenter_wpr .chat_box){
        position: relative;
    }
    :deep(.smsCenter_wpr .chat_box textarea){
        background: #fff;
    }

    /* ------------ scheduled modal style ------------- */
    :deep(.modal.profile_modal.schedule > .modal_container){
        max-width: 1360px;
        margin: 5px;
        width: calc(100% - 10px);
    }
    :deep(.modal.profile_modal.schedule > .modal_container > .modal_body) {
        padding: 15px 30px;
        flex-direction: column;
        background: #f5f5f5;
    }
    :deep(.result_wpr table td){
        padding: 18px 10px;
        font-size: 11px;
        line-height: 16px;
    }
    :deep(.result_wpr table td.email),
    :deep(.result_wpr table td.schedule){
        width: 155px;
    }

    @media(max-width: 1399px){
        .back_btn{
            left: 10px;
            top: 20px;
        }
    }
    @media(max-width: 1199px){
        .sms_action{
            padding: 8px;
            flex: 0 0 140px;
            margin-right: 10px;
        }
        .sms_action ul li{
            padding: 8px 12px;
        }
        .smsCenter_wpr .user_list{
            flex: 0 0 300px;
        }
        .smsCenter_wpr .contact_area{
            width: 450px;
        }
        .chat_listing{
            max-width: 300px;
            margin-left: 150px;
        }
    }
    @media(max-width: 991px){
        .chat_listing{
            max-width: 100%;
            margin-left: auto;
        }
        .chat_listing :deep(.swiper-wrapper){
            justify-content: center;
        }
        .smsCenter_wpr{
            height: calc(100% - 60px);
        }
        .msg_board :deep(.chat) {
            height: calc(100vh - 310px);
        }
        .top_bar button.contact_toggle, .smsCenter_wpr .user_list .close_btn{
            display: block;
        }
        .smsCenter_wpr .sms_action, .smsCenter_wpr .user_list{
            margin-right: 0;
            border-radius: 0;
            border-right: 1px solid #f3f3f3;
        }
        .smsCenter_wpr .contact_area{
            position: fixed;
            top: 0;
            bottom: 0;
            width: 440px;
            left: -440px;
            z-index: 12;
            transition: all 0.3s ease-in-out;
            margin: 0;
        }
        .contact_area .toggle_bar{
            display: flex;
        }
        .smsCenter_wpr .contact_area.show{
            box-shadow: 0 1px 20px rgba(0,0,0,0.07);
            left: 0;
        }
        .top_bar .back_btn{
            position: static;
            background: #fff;
            font-size: 14px;
            flex-shrink: 0;
            margin-right: 8px;
        }
        .refresh_btn{
            padding: 7px;
        }
        .refresh_btn label{
            display: none;
        }
        .msg_board .header-search-main{
            display: block;
            position: relative;
            right: auto;
            left: auto;
            top: auto;
        }
        .msg_board .header-search-main .form-field input{
            height: 45px;
        }
    }
    @media(max-width: 499px){
        .chat_listing :deep(.swiper-wrapper){
            justify-content: center;
        }
        .respective_content{
            min-height: auto;
        }
        .inner_pages{
            padding: 0;
        }
        .top_bar{
            padding: 0 15px;
        }
        .top_bar .back_btn{
            background: #eaeaea;
        }
        .msg_board .user_header h3{
            font-size: 16px;
            line-height: 23px;
        }
        .top_bar .user_box{
            display: block;
        }
        .refresh_btn{
            background: #e1eeff;
        }
        .top_bar button.contact_toggle, .tutorial_btn{
            display: none;
        }
        .top_bar button.filter_sidebar{
            display: flex;
        }
        .chat_listing .chat_tab{
            padding: 5px 10px;
        }
        .smsCenter_wpr{
            height: calc(100vh - 110px);
            margin-top: 5px;
        }
        .smsCenter_wpr .sms_action{
            position: fixed;
            top: 0;
            bottom: 0;
            left: -160px;
            width: 160px;
            padding: 15px;
            z-index: 12;
            transition: all 0.3s ease-in-out;
            margin: 0;
        }
        .smsCenter_wpr .sms_action.show{
            left: 0;
            box-shadow: 0 1px 20px rgba(0,0,0,0.07);
        }
        .smsCenter_wpr .user_list{
            border-right: 0;
            flex: 0 0 100%;
            padding: 15px;
        }
        .infinite-wrapper.message_list > li{
            background: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid #f3f3f3;
            padding-bottom: 20px;
        }
        .infinite-wrapper.message_list > li:last-of-type{
            border: 0;
        }
        .smsCenter_wpr .contact_area, .smsCenter_wpr .contact_area.show{
            position: static;
            width: 100%;
            box-shadow: none;
            flex-direction: column;
        }
        .mob_screen .header-search-main{
            display: block;
            position: relative;
            right: auto;
            left: auto;
            top: auto;
        }
        .mob_screen .header-search-main .form-field{
            width: 100%;
        }
        .mob_screen .header-search-main .form-field input{
            padding-right: 0;
        }
        .contact_area > .toggle_bar{
            display: none;
        }
        .smsCenter_wpr .msg_board{
            background: #fff;
            position: fixed;
            top: 0;
            bottom: 0;
            right: -100%;
            width: 100%;
            z-index: 12;
            transition: all 0.3s ease-in-out;
            margin: 0;
        }
        .smsCenter_wpr .msg_board.show{
            right: 0;
        }
        .smsCenter_wpr .msg_board .chat_board{
            border-radius: 0;
            background: #fff;
        }
        .msg_board .header-search-main{
            display: none;
        }
        .msg_board :deep(.chat) {
            max-height: calc(100vh - 205px);
        }
        .msg_board .user_header{
            padding-left: 15px;
        }
        .msg_board .user_header .back{
            display: flex;
        }
        :deep(.smsCenter_wpr .chat_box){
            padding: 0 15px;
        }
        :deep(.smsCenter_wpr .chat_box textarea){
            background: #f5f5f5;
        }
        .smsCenter_wpr .message_list .user_info .msg_cont .read{
            display: none;
        }
        .smsCenter_wpr .msg_board .user_header .read{
            display: flex;
        }
        .sms_action ul.other_actions{
            margin-top: 0;
        }
        .sms_action ul.other_actions li{
            margin: 0 0 10px 0;
        }
        :deep(.modal.profile_modal.schedule .scroll_table){
            overflow: visible;
        }
        :deep(.modal.profile_modal.schedule .result_wpr table){
            min-width: 100% !important;
        }
        :deep(.result_wpr table th:not(.selectbox, .userbox, .schedule, .empty)),
        :deep(.result_wpr table td:not(.selectbox, .userbox, .schedule, .empty)){
            display: none;
        }
        :deep(.result_wpr table td.userbox .dropdown_wpr.active){
            display: block;
        }
    }
</style>
